@import 'scss/default';
@import "scss/base/colors";
@import 'scss/vendors/include-media';


.stand-grid{
    @extend .grid-col;
    grid-row-gap: 20px;

    @include media('>=660px', "<=desktop") {
        grid-template-columns: repeat(2, 1fr);
    }

    @include media('>desktop') {
        grid-template-columns: repeat(3, 1fr);
    }

    &-item{
        @extend .bg-light-bordered;
        padding: 11px 12px 11px 10px !important;
        height: 100%;
        display: flex;
        flex-direction: column;
        min-height: 356px;

        &.three-row{
            min-height: 435px;
        }

        &.new-badge{
            position: relative;
        }

        .info{
            p{
                min-height: 32px !important;
            }
        }

        &-body{
            &:not(.grid-two-col){
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .group{
                    button{
                        width: 100%;
                        padding: 5px;
                    }

                }

                .craft-grid-item{
                    max-width: 74px;

                    @include media('<=1223px') {
                        max-width: 60px;
                    }

                    @include media('<=1023px') {
                        max-width: 80px;
                    }

                    @include media('<=767px') {
                        max-width: 60px;
                    }

                    @include media('<=659px') {
                        max-width: 90px;
                    }

                    @include media('<=400px') {
                        max-width: 60px;
                    }

                    .label{
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        display: block;
                        width: 100%;
                        overflow: hidden;
                    }
                }
            }

            .grid-two-col{
                margin: 10px 0;
                grid-column-gap: 10px;
            }
        }

        .link-button{
            width: 100%;
        }

        &.one-craft{
            //min-height: 240px;

            button{
                width: 100%;
            }


            .stand-grid-item-body{
                height: 100%;

                .craft-grid{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }

            .craft-grid-item{
                width: 125px;
                height: 125px;

                button{
                    display: flex;
                    justify-content: center;
                }

                .anticon{
                    font-size: 55px;
                }

                .label{
                    font-size: 12px;
                }
            }

            .craft-one-action{
                margin-top: 20px;
            }

            .actions-wrap{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .group{
                height: 85px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @include media('<=360px') {
                    button{
                        font-size: 10px;
                        padding: 10px;
                    }
                }

                & + a{
                    margin-top: 20px;
                }
            }
        }
    }
}

.stands-simple-grid{

    &-item{
        display: flex;
        align-items: center;

        &:hover{
            background-color: rgba(var(--secondary-bg-color-rgb), var(--opacity15));
        }

        & > *:first-child{
            flex: 1;
        }

        h4{
            margin: 0;
        }

        &-wrap{
            padding: 8px 0;
        }

        & > button{
            background-color: transparent;
            border: transparent;
            display: flex;

            align-items: center;
            text-align: left;

            & > *:first-child{
                flex: 1;
                align-items: flex-start;
            }

        }

        .info{
            @extend .text-label;

            span{
                & + *:before{
                    content: "|";
                    display: inline-block;
                    padding: 0 3px;
                }
            }
        }
    }
}

.stand-log-grid{

    &-item{
        @extend .color-primary-op60;

        display: flex;
        padding: 10px 5px !important;


        & > *{
            align-items: center;
            display: flex;
            line-height: 1.2em;
            font-size: 10px;
            padding: 0 8px;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                height: 100%;
                width: 1px;
                background-color: var(--primary-bg-color);
            }

            &:first-child:before{
                display: none;
            }

        }


        .name{
            @extend .color-primary;

            flex: 2;
            font-weight: bold;
            justify-content: center;

        }

        .description{
            flex: 3;
        }

        .time{
            flex: 1;
            font-size: 12px;
            flex-direction: column;
            justify-content: center;

            b{
                @extend .color-primary;
                display: block;
            }
        }

        &-wrap{
            & + &{
                padding-top: 8px;
            }
        }
    }

}