$primary-font: 'Titillium Web', sans-serif;

$primary-font-size: 14px;
$primary-line-height: 1.5em;

$magenta : #E5027D;
$white: #FFFFFF;
$dark-grey: #222B33;

$background: $dark-grey;

$error: #E84A4D;
$success: #44D13F;
$warning: #F8DC00;