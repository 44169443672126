.job-change-flag-button.ant-btn-default{
    width: 24px !important;
    min-width: 20px !important;
    height: 24px !important;
    padding: 0 !important;
    font-size: 16px !important;
    line-height: 0;
    color: rgba(var(--primary-text-color-rgb), var(--opacity40)) !important;


    .anticon{
        position: relative;
        left: 1px;
        top: 2px;
    }

    &[disabled]{
        color: rgba(var(--primary-text-color-rgb), var(--opacity40)) !important;
        background-color: transparent !important;

        &:hover,
        &:after,
        &:hover:after{
            background-color: transparent !important;
            color: rgba(var(--primary-text-color-rgb), var(--opacity40)) !important;
        }

        &.active{
            color: var(--error-color) !important;

            &:after,
            &:hover{
                background-color: transparent !important;
                color: var(--error-color) !important;
            }
        }
    }

    &:hover {
        background-color: rgba(var(--primary-text-color-rgb), var(--opacity15)) !important;
        color: rgba(var(--primary-text-color-rgb), var(--opacity40)) !important;
    }

    &.active{
        color: var(--error-color) !important;

        &:hover{
            color: var(--error-color) !important;
        }
    }
}