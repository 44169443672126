@import "scss/base/colors";
@import "scss/base/helpers";
@import "scss/vendors/include-media";

.craft-grid{

    &.grid-five-col{
        grid-column-gap: 5px;
        grid-row-gap: 5px;
    }

    &-item{
        @extend %transition;
        background-color: var(--primary-bg-color);
        border-radius: 6px;
        padding: 10px 5px 4px;
        position: relative;
        display: flex;
        flex-direction: column;

        &:hover{
            background-color: rgba(var(--primary-bg-color-rgb), var(--opacity40));
        }

        .label{
            font-size: 9px;
            text-align: center;
            color: inherit;
            line-height: 1.2em;
            min-height: 22px;
        }

        .anticon{
            font-size: 38px;
        }

        &.delete,
        &.new{

        }

        &.overdue{
            color: var(--error-color);
        }


        &.new,
        &.waiting,
        &.in_progress{
            color: var(--warning-color);
        }

        &.check,
        &.double_check,
        &.completed{
            color: var(--success-color);
        }

        button{
            cursor: pointer;
            background-color: transparent;
            padding: 0;
            border: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 100%;
        }

        .count-tag{
            position: absolute;
            top: 33px;
            right: 18%;
            font-size: 10px;
            font-weight: bold;
            background-color: var(--secondary-bg-color);
            color: var(--primary-bg-color);
            width: 14px;
            height: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            line-height: 1em;
        }

        .job-tag{
            position: absolute;
            top: -2px;
            font-size: 10px;
            font-weight: bold;

            &.claim{
                left: 50%;
                transform: translate( -40%, 0);
            }

            &.damage-status{
                top: 2px !important;
                line-height: 1em;
                left: 5px;

                .anticon{
                    font-size: 13px;
                }
            }

            &.last{
                left: 5px;
            }

            &.damage{
                right: 5px;
            }

            &.protocol{
                right: 5px;
            }

        }
    }
}

.craft-overview-grid{
    &-wrap{
        margin-bottom: 20px;
    }

    &-item{
        margin-top: 10px;

        h3{
            margin: 2px;
            font-size: 12px;
            display: flex;
            align-items: center;

            @include media('>=phone') {
                font-size: 14px;
            }

            @include media('>=tablet') {

                .anticon{
                    font-size: 26px !important;
                }
            }

            .anticon{
                margin-right: 8px;
                font-size: 20px;
            }
        }
    }


}

