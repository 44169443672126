@import "scss/vendors/include-media";


.damage-photo-modal{

    @include media('>=tablet') {
        &.modal-standard .ant-modal-header {
            padding-top: 18px !important;
        }
    }

    &-wrap{
        button{
            width: 100%;
        }
    }

    .no-data {
        &.damage-photo{
            height: calc((var(--vh, 1) * 100) - 230px);
            color: var(--primary-text-color);
        }
    }

    .ant-modal-body{
        height: calc(var(--vh, 1vh) * 100 - 120px) !important;
        overflow: initial !important;

        & > .loader-item{

        }

    }

    .damage-status-segmented-wrap{
        min-height: 40px;
        margin-top: 10px;
    }

    .damage-photo-action{
        position: absolute;
        bottom: -40px;
        left: 20px;
        right: 20px;
        display: flex;
        justify-content: space-between;
    }

    .damage-photo-viewer-block{
        margin-top: 20px;
    }
}