@import "scss/base/colors";
@import "scss/vendors/include-media";

.exhibition-statistic-grid{

    h3, h4, h5{
        margin: 0;
        color: inherit !important;
    }

    .item{
        padding: 13px 11px;
        border: 1px solid rgba(var(--secondary-bg-color-rgb), var(--opacity15));
        background-color: rgba(var(--secondary-bg-color-rgb), var(--opacity6));
        border-radius: 6px;

        @include media('height<=670px') {
            padding: 7px 11px;
        }

        h3{
            line-height: 1.2em;
        }

        & + .item{
            margin-top: 10px;

            @include media('height<=670px') {
                margin-top: 7px;
            }

            &.crafts{
                margin-top: 30px;

                @include media('height<=670px') {
                    margin-top: 15px;
                }
            }
        }

        &:not(.crafts){
            display: flex;

            & > *{
                flex: 3;
                display: flex;
                justify-content: center;
                flex-direction: column;

                &:first-child{
                    flex: 7;
                }

                &:last-child{

                    border-radius: 6px;
                    display: block;
                    margin-left: 15px;
                    padding: 7px 8px;
                }

            }

            a{
                background-color: var(--secondary-text-color) !important;
                color: var(--primary-text-color);
            }
        }



        &.crafts > * > *,
        &:not(.crafts) > *{
            position: relative;

            &:not(:first-child):before{
                content: "";
                display: block;
                height: 100%;
                position: absolute;
                width: 1px;
                background-color: var(--primary-bg-color);
                left: -8px;
                top: 0;
            }
        }

        .icon-wrap{
            @extend .color-primary;
            display: flex;
            align-items: center;
            justify-content: initial !important;
            flex-direction: row;
            min-width: 120px;
            padding-right: 7px;

            .label{
                margin-left: 9px;
            }

            .anticon{
                background-color: var(--active-bg-color);
                padding: 5px;
                border-radius: 5px;
                font-size: 28px;
            }
        }

        &.two-col{
            & > .icon-wrap{
                flex: 10 !important;
            }
        }

        .label{
            font-size: 10px;
            line-height: 1em;

            @include media('>=phone', '<=tablet') {
                font-size: 12px;
            }

            @include media('>=tablet') {
                font-size: 14px;
            }
        }
    }

    .crafts{

        h5{
            @extend .color-primary;
            line-height: 1em;
            text-transform: initial;
            margin-bottom: 8px;

            @include media('>=phone', '<=tablet') {
                font-size: 12px;
            }

            @include media('>=tablet') {
                font-size: 14px;
            }
        }

    }

    .finished{
        @extend .color-success;
    }
    .overdue{
        @extend .color-error;
    }
    .progress{
        @extend .color-warning;
    }

}