.stand-photo-form{

    .pre-upload-image{

        .no-data{
            height: calc((var(--vh, 1) * 100) - 230px);
            color: var(--primary-text-color);

            &-message{
                margin-bottom: 40px;
            }

            .anticon{
                font-size: 180px;
            }


            & + .actions{
                .group{
                    width: 100% !important;
                }
            }
        }

        .image-preview{
            height: calc((var(--vh, 1) * 100) - 230px);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .empty{
            display: block;
            width: 100%;


            .ant-upload{
                width: 100%;

                button{
                    width: 100% !important;
                }
            }
        }

        .actions{
            margin-top: 30px;
            display: flex;
            justify-content: space-between;

            .group{
                display: flex;
                justify-content: center;


                button{
                    height: 34px;
                    width: 34px;
                }

                & > *{
                    display: flex;
                    align-items: center;

                    & + * {
                        margin-left: 10px;
                    }
                }
            }
        }

        button{
            display: flex;
            align-items: center;
            line-height: 1em;

            .anticon{
                font-size: 20px;
                line-height: 0;
            }

            .anticon-sync{
                font-size: 18px !important;
            }
        }
    }
}