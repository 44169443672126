@import "scss/base/colors";
@import "scss/vendors/include-media";


.craft-actions-modal{
    .ant-modal-title{
        h3{
            @extend .text-type3-dark-op60;
            margin: 0;
            text-transform: uppercase;
        }

        p{
            @extend .text-type3-dark-op60;
        }
    }

    &-list{
        li + li{
            margin-top: 10px;
        }

        button{
            display: flex;
            align-items: center;
            background-color: transparent;
            border: none;
            padding: 0;
            width: 100%;
            cursor: pointer;

            &:hover{

                .craft-grid-item{
                    background-color: rgba(var(--primary-bg-color-rgb), var(--opacity60));
                }
            }

            .craft-grid-item{
                margin-right: 18px;
                min-width: 42px;
                min-height: 42px;
                display: flex;
                align-items: center;
                justify-content: center;

                @include media('>=tablet') {
                    background-color: var(--primary-bg-color);
                }

                .anticon{
                    font-size: 28px;
                }

                .job-tag{
                    top: 0;
                }

                .anticon-loading{
                    color: var( --primary-text-color ) !important;
                    font-size: 22px;
                }
            }
        }
    }
}