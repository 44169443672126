@import "scss/vendors/include-media";


.job-edit-modal{

    @include media('>=tablet') {
        &.full .ant-modal-header{
            padding-top: 18px !important;
        }
    }

    &-button{
        font-size: 18px !important;
        width: 26px !important;
        height: 26px !important;
        min-width: 18px !important;
        padding: 0 !important;
    }

    .ant-modal-body{
        max-width: 1024px;
        margin: 0 auto;
    }
}

.job-photo-modal{
    &-wrap{
        button{
            width: 100%;
        }
    }
}