@import 'scss/default';


.job-grid{
    @extend .grid-col;
    grid-row-gap: 20px;

    @include media('>=660px', "<=desktop") {
        grid-template-columns: repeat(2, 1fr);
    }

    @include media('>desktop') {
        grid-template-columns: repeat(3, 1fr);
    }


    &-item{
        @extend %transition;
        min-height: inherit;
        background-color: #2f373f !important;
        padding: 13px 12px 11px 10px !important;
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 0;
        width: 100%;

        &-wrap{
            position: relative;
            min-height: 195px;

            @for $i from 1 through 30 {
                &:nth-child(#{$i}) {
                    z-index: #{30 - $i};
                }
            }

        }

        &.open{
            z-index: 4;
        }

        .badge{
            background: var(--active-bg-color);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            height: 26px;
            width: 26px;
            line-height: 0;
            border-radius: 6px;
            position: absolute;
            top: -12px;
            left: 0;

            .anticon{
                top: 2px;
                position: relative;
            }

            &.in_progress{
                .anticon{
                    top: -1px;

                }
            }
        }

        &-hero{
            .title{
                display: flex;
                align-items: center;
                position: relative;

                @include media('width<=370px') {
                    h3,
                    .time-on-task{
                        font-size: 12px !important;
                    }
                }

                h3{
                    font-size: 14px;
                    margin: 0;
                    color: inherit;
                }

                button{
                    position: absolute;
                    right: 0;
                    top: -2px;
                }

                .time-on-task{
                    font-size: 14px;
                    color: var(--primary-text-color);
                    margin-left: 5px;

                    &:before{
                        content: "|";
                        margin-right: 5px;
                    }

                    b{
                        margin-left: 4px;
                        font-weight: 400;
                        color: rgba(var(--primary-text-color-rgb), var(--opacity40)) !important;
                    }
                }

                &.in_progress{
                    color: var(--warning-color);
                }

                &.completed{
                    color: var(--success-color);
                }

                .status-wrap{
                    font-size: 18px;
                    margin-right: 5px;
                }

                margin-bottom: 5px;
            }

            .info{
                .exhibitor{
                    font-size: 10px;
                    min-height: 58px;

                    p{
                        margin-top: 3px !important;
                        white-space: inherit;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
            }
        }

        &-body{

            .ant-collapse{
                border-radius: 6px;
                background-color: var(--secondary-bg-color);
                overflow: hidden;

                &-item{
                    background-color: var(--secondary-bg-color);

                    .ant-collapse-header{
                        justify-content: space-between;
                        flex-direction: row-reverse;
                        align-items: center !important;
                        padding: 5px !important;

                        .ant-collapse-arrow{
                            @extend %transition, .color-active;
                            font-size: 20px !important;
                            margin: 0 !important;
                            transform: rotate(90deg);
                            width: 45px;
                        }
                    }

                    .ant-collapse-content{
                        margin: 0 5px;

                    }

                    .ant-collapse-content-box{
                        padding: 0 0 10px !important;
                        position: relative;

                        .job-edit-modal-wrap{
                            position: absolute;
                            z-index: 1;
                            top: 5px;
                            right: 0;

                            button{
                                color: var(--secondary-text-color) !important;

                                .anticon{
                                    line-height: 0;
                                }
                            }
                        }

                        .description{
                            @extend .text-type3, .text-bold, .text-dark;
                            border-top: 1px solid rgba(var(--secondary-text-color-rgb),  var(--opacity10)) !important;
                            padding: 10px 5px;
                            min-height: 36px;

                            @include media('>=phone') {
                                font-size: 14px;
                            }

                        }

                        .furnishing-selected{
                            padding: 0 0 8px;

                            & > *{
                                line-height: 1em;

                                .col:last-child{
                                    text-align: right;
                                    padding-right:6px;
                                }
                            }
                        }
                    }

                    &-active{
                        .ant-collapse-arrow{
                            transform: rotate(-90deg) !important;

                        }
                    }
                }
            }

            .craft-info{
                display: flex;
                align-items: center;
                justify-content: center;

                .craft-grid-item{
                    min-width: 70px;
                    margin-right: 15px;

                    .job-tag{
                        top: 0;
                    }
                }

                h4{
                    @extend .text-dark;
                    font-size: 16px;
                    margin: 0;
                }

                .subcontractor{
                    @extend  .text-dark;
                    font-size: 10px;
                }

            }

            .user-info{
                @extend .text-label-dark;
                border-top: 1px solid rgba(var(--secondary-text-color-rgb),  var(--opacity10)) !important;
                padding: 10px 5px 0;

                @include media('>=phone') {
                    font-size: 12px;
                }

                & > *{
                    text-transform: none;
                    display: flex;
                    align-items: center;
                    line-height: 1.6em;
                }

                .anticon{
                    margin-right: 4px;
                    font-size: 12px;
                }
            }

            .actions-wrap{
                padding-top: 10px;
                .group ~ *,
                * ~ .group{
                    margin-top: 10px;
                }

                .group{
                    grid-column-gap: 10px;
                }

                .status{
                    width: 100%;
                }

                a{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    line-height: 1em;

                    .anticon{
                        font-size: 18px;
                        margin-right: 5px;
                    }
                }

            }
        }

        .link-button{
            width: 100%;
        }
    }
}