@import "scss/base/colors";

.more-menu{
    width: 100%;

    li + li{
        border-top: 1px solid rgba(var(--secondary-bg-color-rgb), var(--opacity15));
    }

    a{
        @extend .text-white-bold;
        min-height: 52px;
        display: flex;
        align-items: center;

        .anticon{
            margin-right: 20px;
            font-size: 26px;
        }

        &:hover{
            @extend .color-active;
        }
    }
}