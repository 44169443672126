@import "scss/vendors/include-media";

.exhibition-select{
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > span{
        font-size: 18px;
        font-weight: bold;
    }

    &-wrap{
        .label{
            margin-bottom: 0;

            @include media('height<=670px') {
                line-height: 1em;
            }
        }
    }
}