.app{

    main{
        display: flex;
        justify-content: center;
        flex: 1;
        width: 100%;
    }

    &.with-bottom-bar{
        .page{
            padding-bottom: 70px !important;
        }

    }

}