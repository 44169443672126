.job-create-form{

    .ant-radio-group{
        .ant-radio-wrapper{
            flex-direction: row !important;

            &:not(:first-child){
                margin-left: 15px !important;
            }

            .ant-radio{
                margin-right: 7px;
            }
        }
    }


    .furnishing-select-button{
        width: 100% !important;
        margin-bottom: 10px;
    }

    .furnishing-selected{
        margin-bottom: 10px;
    }

    .pre-upload-image{

        .actions{

            .empty{
                width: 100%;

                .ant-upload{
                    display: block;

                }
            }

            .group{
                width: 100%;
                display: flex;
                justify-content: center;
            }

            .add-photo{
                width: 100%;

                .anticon{
                    font-size: 18px;
                }
            }

            & > * + *{
                margin-left: 5px;
            }

            button{
                color: var(--secondary-text-color) !important;
                display: flex;
                align-items: center;
                box-shadow: none !important;

                .anticon-sync{
                    font-size: 18px !important;
                }
            }
        }
    }
}