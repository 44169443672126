.guest-app{

    main{
        display: flex;
        justify-content: center;
        flex: 1;
        width: 100%;
    }

    .container{
        max-width: 1024px;
        width: 100%;
    }
    
}