@import "scss/base/colors";
@import "scss/vendors/include-media";

.craft-contact-list{
    &-item{
        display: flex;
        padding: 6px 11px !important;

        h4{
            margin: 0;
        }

        & > *{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            padding-right: 5px;

            &.icon{
                align-items: flex-start;

                .craft-grid-item{
                    min-width: 70px;

                    @include media('>=tablet') {
                        min-width: 100px;
                        min-height: 80px;
                        .label{
                            font-size: 12px;
                        }

                        .anticon {
                            font-size: 48px;
                        }
                    }
                }
            }

            &:last-child{
                flex: 2;
                padding-right: 0;
            }

            & + *{
                padding-left: 5px;
                position: relative;

                &:before{
                    content: "";
                    position: absolute;
                    left: 0;
                    height: 100%;
                    width: 1px;
                    background-color: var(--primary-bg-color);
                }
            }
        }

        .complete-at{
            font-size: 12px;

            @include media('>=phone') {
                font-size: 14px;
            }


            & > *{
                line-height: 1.2em;
            }

            .date{
                @extend .text-white-op40;
            }
        }

        .subcontractor{
            h5{
                @extend .text-white-op60;
                line-height: 1.2em;
                text-transform: none;

                @include media('>=phone', '<=tablet') {
                    font-size: 14px;
                }

                @include media('>=tablet') {
                    font-size: 16px;
                }
            }

            .group{
                display: flex;

                & > * + *{
                    margin-left: 5px;
                }
            }
        }

        & + &{
            margin-top: 8px;
        }

    }
}