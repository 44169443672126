@import 'scss/base/colors';
@import 'scss/base/helpers';


.exhibition-selection-list{

    &-wrap{
        width: 100%;
    }

    &.button{

        li + li{
            margin-top: 12px;
        }

        label{
            @extend .text, .bg-light-bordered, %transition;
            padding: 14px 14px 14px 20px;
            display: flex;
            align-items: center;
            min-height: 55px;
            cursor: pointer;
            line-height: 1em;

            b{
                @extend .text-label;
            }

            & > span:not(.ant-radio-button){
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-transform: uppercase;
            }

            &:hover{
                background-color: rgba(var(--secondary-bg-color-rgb), var(--opacity15));
            }

            .anticon{
                @extend .color-active;
                font-size: 18px;
            }
        }
    }
}

.exhibition-document{

    &-list{
        padding-bottom: 100px !important;
    }

    &-item{

        &-wrap{
            & + &{
                margin-top: 10px;
            }
        }

        a{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 11px 8px 8px !important;
            transition: all var(--animation-speed) ease-in-out;

            &:hover{
                background-color: rgba(var(--secondary-bg-color-rgb), var(--opacity15));

                .anticon:not(.document-type){
                    color: var(--active-text-color);
                }
            }
        }

        .info{
            margin: 0 10px;
            flex: 1;

            h4{
                font-size: 12px;
                margin-bottom: 0;

                & + p{
                    color: rgba(var(--primary-text-color-rgb), var(--opacity60)) !important;
                    line-height: 1.2em;
                    font-size: 10px;
                }
            }
        }

        .anticon{
            transition: all var(--animation-speed) ease-in-out;
            color: var(--primary-text-color);
            font-size: 24px;

            &.document-type{
                font-size: 50px;
            }
        }
    }
}