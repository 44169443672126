@import 'scss/base/colors';

.furnishing-selected,
.furnishing-custom-list{

    &-item{
        display: flex;
        padding: 11px 11px !important;

        &-wrap{
            padding-top: 7px;
        }

        .photo-modal-wrap{
            display: flex;
            align-items: center;
            justify-content: center;

            .ant-btn{
                background-color: transparent !important;
            }
        }

    }

    .col{
        flex: 1;
        text-align: center;

        &:not(:first-child){
            max-width: 60px;
        }

        &:first-child{
            flex: 4;
            text-align: left;
        }

        h4{
            margin-bottom: 0;
        }

        & > *{
            line-height: 1.2em;
        }
    }

    .data{
        flex: 1;

        & > h5{
            color: var(--active-text-color) !important;
        }

        .info{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .created{
            display: flex;
            justify-content: space-between;
            font-size: 10px;
            margin-top: 5px;

            &.no-user{
                justify-content: flex-end;
            }
        }
    }

    .action{
        margin-left: 12px;
        align-items: center;
        display: flex;
    }
}

.furnishing-selected{
    .furnishing-custom-list-item{
        padding: 3px 3px !important;

        button{
            @extend .text-dark;
            border: none;
            height: 26px !important;
            width: 26px !important;

            &:hover{
                color: var(--active-text-color) !important;
            }

            .loader-item{
                height: 26px;
                width: 26px;

                .anticon-loading{
                    font-size: 18px;
                }
            }

            &:after{
                height: 26px !important;
                width: 26px !important;
            }

        }

    }

    .ant-input-number{
        height: 30px;

        &-focused{
            input{
                background-color: rgba(var(--primary-bg-color-rgb), var(--opacity15));
            }
        }

        input{
            padding: 4px 2px;
            height: 28px;
            text-align: center;

        }
    }
}


.furnishing-selected,
.furnishing-list{
    .grey &{
        h4{
            @extend .text-dark;
        }

        .text-label{
            @extend .text-dark-op60;
        }
    }

}
