@import 'scss/base/colors';
@import 'scss/base/helpers';
@import 'scss/vendors/include-media';


.main-menu{
    display: flex;
    justify-content: space-around;
    height: inherit;

    &-wrap{
        height: 100%;
    }

    li{
        position: relative;

        .count-new-badge{
            position: absolute;
            background: var(--secondary-bg-color);
            color: var(--active-text-color) !important;
            right: 0;
            top: 0;
            padding: 2px 4px;
            line-height: 1em;
            border-radius: 6px;
            min-width: 15px;
            text-align: center;
        }
    }

    li > *{
        @extend .text-type4, .color-primary-op40;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-width: 62px;
        min-height: 40px;
        font-weight: bold;

        &.active,
        &:hover{
            @extend .color-active;

            .anticon{
                @extend .color-active;
            }
        }

        @include media('<=phone') {
            &:not(.active):hover{
                color: rgba(var(--primary-text-color-rgb),  var(--opacity40)) !important;

                .anticon{
                    color: rgba(var(--primary-text-color-rgb),  var(--opacity40)) !important;
                }
            }
        }

        .anticon{
            @extend .color-primary-op60, %transition;
            font-size: 26px;
            margin-bottom: 3px;
        }
    }
}