@import 'scss/default';


.damage-grid{
    @extend .grid-col;
    grid-row-gap: 20px;

    @include media('>=660px', "<=desktop") {
        grid-template-columns: repeat(2, 1fr);
    }

    @include media('>desktop') {
        grid-template-columns: repeat(3, 1fr);
    }


    &-item{
        @extend %transition;
        min-height: inherit;
        background-color: #2f373f !important;
        padding: 13px 12px 11px 10px !important;
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 0;
        width: 100%;

        &-wrap{
            position: relative;
            min-height: 168px;
        }

        &-hero{
            .title{
                display: flex;
                align-items: center;
                position: relative;

                @include media('width<=370px') {
                    h3{
                        font-size: 12px !important;
                    }
                }

                h3{
                    font-size: 14px;
                    margin: 0;
                    color: inherit;
                }

                &.in_progress{
                    color: var(--warning-color);
                }

                &.completed{
                    color: var(--success-color);
                }

                .status-wrap{
                    font-size: 18px;
                    margin-right: 5px;
                }

                margin-bottom: 5px;
            }

        }

        &-body{
            display: flex;
            flex-direction: column;
            flex: 1;

        }

        .link-button{
            width: 100%;
        }
    }
}