@import 'scss/base/colors';

.top-menu-bar{
    background-color: rgba(var(--primary-bg-color-rgb), var(--opacity95));
    padding: 7px 20px 6px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;
    border-bottom: 1px solid #000000;
    min-height: 50px;

    .main-wrap{
        & > * {
            align-items: center;
        }
    }

    .logo{
        font-size: 34px;
    }

    .back-button{

        &.icon.rounded{
            @extend .color-primary;

            font-size: 28px !important;

            &:hover{
                @extend .color-active;
            }
        }

        .anticon{
            transform: rotate( -180deg );
        }
    }

    .ant-col{
        display: flex;

        & > .right-part,
        & > .left-part{
            display: flex;
            align-items: center;
            line-height: 1em;
        }

        .center-part{
            @extend .text-white-op60;
            flex: 2;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        & > .right-part{
            flex: 1;
            justify-content: flex-end;

            &  > * {
                display: flex;
                align-items: center;

                & + *{
                    margin-left: 12px;
                }
            }
        }

        & > .left-part{
            flex: 1;
        }
    }

}