@import "scss/vendors/include-media";

.hall-plan{

    &-wrap{
        height: calc( calc(var(--vh, 1vh) * 100) - 110px);
        min-height: 350px;
        padding: 11px 0 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h3{
            text-align: center  ;
            color: var(--secondary-text-color);
        }

    }

    &-actions{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;

        .anticon{
            font-size: 26px;
        }
    }

}