@import "scss/base/colors";

.hall-overview-grid{
    &-wrap{
        margin-bottom: 20px;
    }

    &-item{
        margin-top: 12px;

        h3{

            margin: 5px;
            font-size: 14px;
            display: flex;
            align-items: center;
            text-transform: uppercase;

            .anticon{
                font-size: 14px;
            }

            a{
                color: var(--primary-text-color);

                &:hover{
                    color: var(--active-text-color);
                }
            }
        }
    }


}