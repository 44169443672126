@import "scss/vendors/include-media";

.damage-preview-block{
    display: flex;
    border-radius: 6px;
    background-color: var(--secondary-bg-color);
    overflow: hidden;
    padding: 5px;
    height: 100%;
    flex: 1;
    align-items: center;
    justify-content: space-between;

    //&.new{
    //    color: var(--primary-text-color);
    //}

    &.new{
        color: var(--warning-color);
    }

    &.check,
    &.double_check{
        color: var(--success-color);
    }

    .status-wrap{
        background-color: var(--primary-bg-color);
        border-radius: 6px;
        transition: all var(--animation-speed) ease-in-out;
        position: relative;
        display: flex;
        flex-direction: column;

        &:hover {
            background-color: rgba(var(--primary-bg-color-rgb), var(--opacity70));
        }

        button{
            background-color: transparent;
            border: none;
            cursor: pointer;
            padding: 6px 5px 4px;
            display: flex;
            flex-direction: column;
            height: 50px;
            width: 55px;
            align-items: center;

            .anticon{
                font-size: 18px;
                line-height: 1px;
            }

            .status{
                font-size: 9px;
                line-height: 1.1em;
            }

        }
    }

    .action{
        display: flex;
        justify-content: space-between;

        a,
        button{
            min-width: 80px !important;
            max-width: 90px;
            padding: 0 15px;

            .anticon{
                font-size: 18px;
                margin-right: 5px;
            }

            & + *{
                margin-left: 5px;
            }
        }

    }

    .count{
        color: var(--secondary-text-color) !important;
        font-size: 18px;
        font-weight: bold;
    }


}

.damage-photo-viewer-block{
    position: relative;
    background-color: rgba(var(--secondary-bg-color-rgb), var(--opacity6));
    border: 1px solid rgba(var(--secondary-bg-color-rgb), var(--opacity15));
    border-radius: 6px;

    .img-wrap{
        height: calc( var(--vh) * 100 - 356px);
        width: calc(100vw - 40px);
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            max-width: 100%;
            max-height: 100% !important;
        }

        &.loading{
            background-color: rgba( var(--secondary-bg-color-rgb), var(--opacity15) );
            justify-content: center;

            img{
                display: none;
            }
        }
    }

    .damage-photo-info{
        color: var(--primary-text-color);
        display: flex;
        padding: 3px 8px 3px 12px;
        font-size: 12px;
        line-height: 1.2em;
        height: 36px;
        font-weight: 600;

        .number{
            flex: 1;
        }

        .date{
            flex: 2;
        }

        .author{
            flex: 3;
        }

        & > *{
            position: relative;

            &:not(:last-child){
                padding-right: 6px;
            }
        }

        & > *:before{
            content: "";
            height: 30px;
            width: 1px;
            position: absolute;
            background-color: var(--primary-bg-color);
            display: block;
            left: -6px;

        }
    }

    .delete-wrap{
        position: absolute;
        right: 10px;
        bottom: 166px;

        & > button.icon{
            padding: 4px;
            color: var(--primary-text-color) !important;
            background-color: rgba(var(--primary-bg-color-rgb), var(--opacity60)) !important;

            &:hover{
                background-color: rgba(var(--secondary-bg-color-rgb), var(--opacity60)) !important;
                color: var(--active-text-color) !important;
            }
        }
    }

    .photo-description-wrap{
        height: 120px;
        padding: 10px 6px;
        display: flex;

        @include media('>=tablet') {
            padding: 10px 15px;
        }

        .text{
            flex: 1;
            font-size: 12px;
            font-weight: 500;

            @include media('>=tablet') {
                font-size: 14px !important;
            }

            textarea{
                height: 100%;
                font-size: 12px !important;
                font-weight: 500;
                resize: none;
                background-color: transparent;
                border: 1px solid rgba(var(--secondary-bg-color-rgb), var(--opacity40));
                color: var(--primary-text-color);

                @include media('>=tablet') {
                    font-size: 14px !important;
                }

                &:hover,
                &:focus{
                    border: 1px solid rgba(var(--secondary-bg-color-rgb), var(--opacity40));

                }
            }

            .ant-btn-link{
                color: rgba(var(--secondary-bg-color-rgb), var(--opacity40));

                &:hover{
                    color: rgba(var(--secondary-bg-color-rgb), var(--opacity100))
                }
            }
        }

        .action{
            position: relative;
            width: 30px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            button{

                &.ant-btn-ghost{
                    border: initial;
                }

                &.ant-btn-primary{
                    &[disabled]{
                        background-color: rgba(var(--secondary-bg-color-rgb), var(--opacity15));
                    }
                }
            }


        }

    }

}