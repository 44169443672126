@import 'scss/base/colors';
@import 'scss/base/helpers';


.hall-list{

    li + li{
        margin-top: 10px;
    }

    li > span,
    li > a{
        @extend .text, %transition;
        background-color: rgba(var(--secondary-bg-color-rgb), var(--opacity6));
        border: 1px solid rgba(var(--secondary-bg-color-rgb), var(--opacity15));
        padding: 14px 14px 14px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 6px;
        min-height: 54px;
        cursor: pointer;
        line-height: 1em;

        &:not(span):hover{
            @extend .color-active;
            background-color: rgba(var(--secondary-bg-color-rgb), var(--opacity15));
        }

        .anticon{
            font-size: 24px;
        }
    }

    li > span{
        border: none;
    }
}