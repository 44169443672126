@import 'scss/vendors/include-media';

.stand-materials-block{

    .row{
        grid-row-gap: 7px;
        grid-column-gap: 7px;
    }

    button{
        width: 100%;

        @include media('<=360px') {
            font-size: 10px;
            padding: 10px;
        }
    }

    a{
        .anticon{
            font-size: 18px;
        }

        .anticon + *{
            margin-left: 8px;
        }
    }

    .grid-two-col-to-one button{
        justify-content: center !important;
    }
}

.stand-damages-block{

    .damage-preview-block{
        padding: 6px 11px;
    }

    .status-wrap{
        button {
            width: 100%;
            height: 100%;
            justify-content: center;

            .anticon{
                font-size: 30px;
            }

            .status{
                line-height: 2em;
            }
        }
    }

    @include media('<=tablet'){
        .status-wrap{
            width: 70px;
            height: 74px;
        }
    }

    @include media('>tablet') {

        .status-wrap{
            width: 100px;
            height: 84px;

            button{
                .status{
                    font-size: 12px;
                }
            }
        }
    }

}

[lang="de"]{
    .stand-materials-block{
        button{
            justify-content: left;
        }

        a{
            justify-content: left;
        }
    }
}

.stand-overview-block{

    .row{

        h5{
            line-height: 1.2em;
            margin: 5px 0 0;

            & + *{
                line-height: 1.2em;
            }
        }

        & > *{
            position: relative;
            padding: 0 5px 5px 6px;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                height: 100%;
                width: 1px;
                background-color: var(--primary-bg-color);
            }
        }

        &:first-child{
            padding-bottom: 5px;
        }

        & + .row{
            padding-top: 6px;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 1px;
                width: 100%;
                background-color: var(--primary-bg-color);
            }
        }

    }

    .holder{
        & > *:last-child{
            margin-top: 12px;
        }
    }
}

.keys-logs-block{
    grid-column-gap: 7px !important;

    .anticon{
        font-size: 18px;
        margin-right: 8px;
    }
}

.stand-photo-block{

    & > h3{
        margin: 30px 0;
        text-align: center;
    }
}

.stand-search-block{
    &-head{
        padding: 0 20px ;
        display: flex;
        align-items: center;

        .search-field-wrap{
            flex: 1;
        }
    }

    &-body{
        margin-top: 20px;

        .anticon{
            color: var(--active-text-color);
            font-size: 18px;
        }
    }

    .search-field-wrap{
        .anticon{
            font-size: 22px;
            line-height: 1em;
            display: flex;
            align-items: center;
        }

        .ant-input-clear-icon{
            .anticon{
                font-size: 18px;
            }
        }
    }
}

