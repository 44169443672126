@import 'scss/vendors/include-media';


.sign-in-form{
    text-align: left;
    max-width: 380px;
    width: 100%;

    .form-actions{
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }

    .login-form-link{
        text-decoration: underline;

        &:hover{
            color: var(--active-text-color);
        }
    }

    .logo{
        display: flex;
        align-items: center;

        &-holder{
            display: flex;
            justify-content: center;
            margin-bottom: 30px;
        }

        &-text{
            color: var(--primary-text-color);
            margin-left: 12px;
            font-size: 18px;
            letter-spacing: 1px;
        }

        .anticon{
            font-size: 55px;
        }
    }
}