﻿$breakpoints: ('xs-phone': 320px,
  'phone': 480px,
  'tablet': 768px,
  'desktop': 1024px,
  'widescreen': 1200px);

$media-expressions: ('screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

@import 'vendors/include-media';

@import 'base/variables';
@import 'base/functions';
@import 'base/mixins';
@import 'base/helpers';
@import 'base/colors';


:root {

    --primary-bg-color: #{hexToStr($background)};
    --primary-bg-color-rgb: #{hexToRGB($background)};

    --secondary-bg-color: #{hexToStr($white)};
    --secondary-bg-color-rgb: #{hexToRGB($white)};

    --active-bg-color: #{hexToStr($magenta)};
    --active-bg-color-rgb: #{hexToRGB($magenta)};

    --primary-text-color: #{hexToStr($white)};
    --primary-text-color-rgb: #{hexToRGB($white)};

    --secondary-text-color: #{hexToStr($dark-grey)};
    --secondary-text-color-rgb: #{hexToRGB($dark-grey)};

    --active-text-color: #{hexToStr($magenta)};

    --error-color:  #{hexToStr($error)};
    --error-color-rgb:  #{hexToRGB($error)};
    --success-color:  #{hexToStr($success)};
    --warning-color:  #{hexToStr($warning)};

    --opacity100: 1;
    --opacity95: 0.95;
    --opacity70: 0.7;
    --opacity60: 0.6;
    --opacity40: 0.4;
    --opacity15: 0.15;
    --opacity10: 0.10;
    --opacity6:  0.06;
    --opacity0: 0;

    --animation-speed: 0.3s;

    --antd-wave-shadow-color: #{hexToStr($magenta)};

    --vh: 1px;
}

::selection {
    background: var(--primary-bg-color);
}

body,
p{
    margin: 0;
}

ul{
    @extend %list-reset;
}

html, body, #root{
    min-height: 100%;
}

body{
    background-color: var(--primary-bg-color);
    color: var(--primary-text-color);
    font: 400 $primary-font-size $primary-font;
    line-height: $primary-line-height;
    overflow-y : scroll;
}

.guest-app,
.app{
    min-height: 100vh;

    display: flex;
    flex-direction: column;
}

h1{
    font-size: 24px;
    font-weight: bold;
    line-height: 1.4em;
    color: var(--primary-text-color);
}

h3{
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33em;
    color: var(--primary-text-color);
}

h4{
    font-size: 14px;
    font-weight: bold;
    line-height: 1.33em;
    color: var(--primary-text-color);
}

h5{
    @extend .text-label;
    font-weight: bold;
}

.logo{
    color: var(--active-text-color);
}

.grid{
    display: grid;
    grid-row-gap: 30px;
    grid-column-gap: 20px;

    &-six-col{
        @extend .grid;
        grid-template-columns: repeat(6, 1fr);
        grid-auto-rows: minmax(auto, auto);

    }

    &-five-col{
        @extend .grid;
        grid-template-columns: repeat(5, 1fr);
        grid-auto-rows: minmax(auto, auto);

    }

    &-four-col{
        @extend .grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: minmax(auto, auto);
    }

    &-three-col{
        @extend .grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: minmax(auto, auto);
    }

    &-two-col{
        @extend .grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(auto, auto);

        &-to-one{
            display: grid;
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }

    &-col{
        @extend .grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: minmax(auto, auto);
    }


}

.ant-scrolling-effect{

    @include media('>=tablet') {
        .bottom-menu-bar{
            padding-right: 17px !important;
        }
        .top-menu-bar{
            padding-right: 37px;
        }
    }

}

.link{

    &-text{
        @extend .text-active;

        &:hover{
            color: rgba(var(--primary-text-color-rgb),  var(--opacity60));
        }

        &:focus{
            @extend .text;
        }

        &-bold{
            @extend .link-text, .text-bold;
        }
    }

    &-active{
        color: var(--secondary-button-color);

        &:hover{
            color: var(--active-button-color);
        }
    }

    &-button{
        @extend .ant-btn, %transition;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &-ghost{
            @extend  .link-button, .ant-btn-ghost;

            .anticon{
                font-size: 12px;
                margin-left: 5px;
            }

            &:hover{
                color: var(--primary-button-color);
            }


        }

        &-default{
            @extend  .link-button, .ant-btn-default;
        }

        &-primary{
            @extend  .link-button, .ant-btn-primary;

            &:hover{
                color: var(--primary-button-color);
            }
        }

    }
}

.ant-spin{
    color: inherit !important;
}

.ant-btn{
    height: 34px;
    min-width: 110px;
    padding: 0 20px;
    font-size: 12px;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    border-radius: 6px;
    position: relative;
    z-index: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media('>=tablet') {
        height: 38px;
        font-size: 13px;
    }

    & > span{
        position: relative;
        z-index: 1;
    }

    &-loading{
        padding-left: 20px;
    }

    &:after{
        @extend %transition;
        content: "";
        display: block;
        z-index: 0;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        background-color: rgba(var(--secondary-bg-color-rgb),  0);
    }

    &-link{
        @extend .ant-btn, %transition;
        font-size: 14px;
        color: var(--primary-text-color);
        font-family: inherit !important;
        height: auto;
        width: auto !important;
        min-width: initial !important;
        padding: 0;
        letter-spacing: initial;
        text-transform: initial;
    }

    &.icon{
        font-size: 20px;
        height: auto;
        width: auto;
        min-width: auto;
        padding: 3px;

        color: var(--primary-text-color);

        &.rounded{
            background-color: transparent !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.rounded-primary{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(var(--secondary-bg-color-rgb), var(--opacity15)) !important;
            color: var(--primary-button-color) !important;

            &:hover{
                background-color: var(--active-bg-color) !important;
                border-color: var(--active-bg-color) !important;
            }

        }
        .loader-item{
            font-size: 17px !important;
            line-height: 1em;
            width: 21px;
            height: 21px;

            .ant-spin{
                line-height: 1em;

                .anticon-loading{
                    font-size: 18px !important;
                }
            }
        }


        .anticon{
            vertical-align: 0;
            line-height: 0;
        }

        &:focus{
            color: var(--primary-text-color);
        }

        &:active {
            color: var(--primary-text-color);
            position: relative;
        }

        &:hover:not(:active){
            color: rgba(var(--primary-text-color-rgb),  var(--opacity60));
        }
    }

    &-default{
        @extend .ant-btn-primary, .color-primary, %transition;
        background-color: rgba(var(--primary-text-color-rgb), var(--opacity15)) !important;
        display: flex;
        align-items: center;
        line-height: 1em;

        &:hover{
            @extend .color-active;
            background-color: transparent !important;

            &:after{
                display: none;
            }
        }

        &:active,
        &:focus{
            @extend .ant-btn-default;
        }

        &.dark{
            color: var(--primary-text-color) !important;
            background-color: rgba(var(--primary-bg-color-rgb), var(--opacity40)) !important;
            font-size: 13px;
            font-weight: normal;
        }

        &[disabled],
        &.disabled{
            @extend .ant-btn-primary;
            color: var(--secondary-text-color) !important;
            background-color: rgba(var(--secondary-bg-color-rgb), var(--opacity15)) !important;

            &:after{
                display: block;
                background-color: rgba(var(--primary-bg-color-rgb), var(--opacity40)) !important;
            }


            &:active,
            &:focus,
            &:hover{
                @extend .ant-btn-default.disabled;
            }
        }

    }

    &-ghost{
        @extend .ant-btn-primary, .color-primary, %transition;
        background-color: transparent !important;
        border: 1px solid rgba(var(--primary-text-color-rgb), var(--opacity40));

        &:hover{
            @extend .color-active;
            background-color: transparent !important;
            border: 1px solid var(--active-text-color);

            &:after{
                display: none;
            }
        }

        &:active,
        &:focus{
            @extend .ant-btn-ghost;
        }

        &.dark{
            color: var(--secondary-text-color) !important;
            border: 1px solid var(--secondary-text-color);
            font-size: 12px;
            font-weight: bold;
        }

    }

    &-primary{
        background-color: var(--active-bg-color);
        color: var(--primary-text-color);


        &:focus{
            @extend .ant-btn-primary;
        }

        &:hover:not(:active){
            @extend .ant-btn-primary;

            &:after{
                background-color: rgba(var(--secondary-bg-color-rgb),  var(--opacity15));
            }

        }

        &:active{
            @extend .ant-btn-primary;

            &:after{
                background-color: rgba(var(--primary-bg-color-rgb),  var(--opacity10));
            }
        }

        &[disabled]:hover,
        &[disabled]{
            @extend .ant-btn-primary;
            color: var(--secondary-text-color);

            &:after{
                background-color: rgba(var(--primary-bg-color-rgb),  var(--opacity40));
            }
        }

        &.status{
            color: var(--secondary-text-color);
            font-weight: bold;

            & > * {
                display: flex;
                align-items: center;
                line-height: 1em;
            }

            .anticon{
                font-size: 18px;
                margin-right: 5px;
            }

            //&.waiting{
            //    color: var(--primary-text-color);
            //}

            &.completed{
                background-color: var(--success-color);
            }

            &.waiting,
            &.in_progress,
            &.in-progress{
                background-color: var(--warning-color);
            }

        }

    }
}

.ant-form{
    [role="alert"]{
        @extend .text-type3-error;
        padding-top: 2px;
    }

    .field-group &-item{
        margin-bottom: 0;
    }

    .field-group,
    & *:not(.field-group) &-item{

        margin-bottom: 24px;

        &:last-child{
            margin-bottom: 0;
        }
    }

    .ant-form-item-explain{
        min-height: 24px;
        position: absolute;
        bottom: -24px;
    }

    .field-group{
        display: flex;


        & > *{
            display: flex;
            flex-direction: column;
            flex: 1;

            .ant-col-24{
                flex: initial;
            }
        }

        & > * + *{
            margin-left: 22px;
        }
    }

    .ant-form-item-has-error{
        .ant-input-number,
        .ant-input-password,
        .ant-input{
            border-color: var(--error-color) !important;
            box-shadow: none !important;
        }

        .ant-checkbox{
            .ant-checkbox-inner{
                border-color: var(--error-color) !important;
            }
        }
    }

    .ant-form-item-with-help{
        margin-bottom: 24px;
    }

    .ant-input-number{
        &-handler-wrap{
            display: none;
        }
    }

    .ant-form-item{
        .ant-input-textarea-show-count:after {
            position: absolute;
            bottom: initial;
            top: -20px;
            font-weight: bold;
            font-size: 12px;
            font-family: $primary-font;
            color: var(--primary-text-color);
            right: 0;
        }

        &-label{
            height: auto;
            padding: 0 0 4px;
            line-height: 1em;

            & > label{
                @extend .text-label;
                display: flex;

                &:before{
                    display: none !important;
                }

                &.ant-form-item-required:not(.ant-form-item-required-mark-optional){

                    &:after{
                        @extend .color-error;
                        display: inline-block;
                        margin-right: 4px;
                        position: relative;
                        top: 1px;
                        font-size: 14px;
                        line-height: 1em;
                        content: '*';
                    }

                }

            }
        }

        &.dark{
            .ant-input-number,
            .ant-input{
                border: 1px solid rgba(var(--primary-bg-color-rgb), var(--opacity15)) !important;
            }
        }
    }

    .ant-radio-group-outline{
        display: flex;
    }

}

.input-with-control{
    display: flex;

    .control{
        display: flex;
        flex : 1;
        margin-left: 10px;

        & > button{
            background-color: rgba(var(--primary-bg-color-rgb), var(--opacity40)) !important;
            transition: all var(--animation-speed) ease-in-out;
            color: var(--primary-text-color) !important;
            justify-content: center;
            border-radius: 6px;
            border: none;
            width: 32px;
            padding: 0;
            font-size: 24px;
            font-weight: bold;
            line-height: 1em;
            height: 32px;
            cursor: pointer;
            display: flex;
            align-items: center;

            span{
                position: relative;
                top: -2px;
            }

            & + button{
                margin-left: 5px;
            }

            &:hover{
                background-color: var(--active-bg-color) !important;
            }
        }
    }
}

.ant-radio{

    &-wrapper{
        display: flex;
        flex-direction: row-reverse;
        margin-right: 0;

        & > *:not(.ant-radio){
            width: 100%;
            padding: 0;
        }

        &:hover{
            .ant-radio{
                border-color: var(--active-bg-color)  !important;
            }
        }
    }

    &:hover{
        .ant-radio-inner{
            border-color: var(--active-bg-color)  !important;
        }
    }

    &-checked{
        .ant-radio-inner{
            border-color: var(--active-bg-color)  !important;

            &:after{
                background-color: var(--active-bg-color)  !important;
            }
        }
    }

    &-input{

        &:focus{


            & + .ant-radio-inner{
                box-shadow: 0 0 0 3px rgba(var(--active-bg-color-rgb), var(--opacity6));
                border-color: var(--active-bg-color)  !important;
            }
        }
    }
}

.radio-list{
    padding: 3px 0;

    li + li{
        margin-top: 14px;
    }

    .ant-radio-wrapper{
        @extend .text-dark-bold;
        line-height: 1em;
        text-transform: uppercase;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        b{
            @extend .text-label-dark;
        }

        & > *:not(.ant-radio){
            width: 100%;
            padding: 0;
        }

        .ant-radio{
            transform: translate( 0, -3px);
        }
    }
}

textarea.ant-input{
    padding: 6px 10px;
    min-height: 80px !important;
}

.ant-input-number{
    @extend .ant-input;
    width: 100%;

    &-input{
        height: 32px;
        border-radius: 6px;
        padding: 4px 11px;
        outline: none;
    }

    &-focused {
        box-shadow: none;
        @extend .ant-input:hover;
    }
}

.ant-input{
    border: 1px solid transparent;

    &-affix-wrapper,
    :not(#{&}-affix-wrapper) > & {
        border: none;
        height: 34px;
        border-radius: 6px;

        &:not(.ant-input-disabled){
            //border-bottom-color:var(--primary-button-bg-color);
        }

        &:not(.ant-input-disabled):hover {
            border-color: var(--active-button-color);
            //border-width: 3px !important;
        }

        &:focus,
        &-focused {
            box-shadow: none;
            @extend .ant-input:hover;
        }
    }

    &-affix-wrapper:not(&-affix-wrapper-disabled){
        &:hover{
            border-color: var(--active-button-color);
            border-width: 3px !important;
        }
    }
}

.ant-select{

    &-focused:not(&-disabled),
    &:not(&-disabled):hover{
        .ant-select-selector{
            border-color: var(--active-text-color) !important;
            border-radius: 6px !important;
            box-shadow: 0 0 0 2px rgba(var(--active-bg-color-rgb), var(--opacity6)) !important;
        }
    }

    &-item-option-selected:not(&-item-option-disabled){
        background-color: rgba(var(--primary-bg-color-rgb), var(--opacity6));

        .anticon-check{
            color: var(--active-text-color) !important;
        }

    }

    &-arrow{
        color: var(--secondary-text-color);
        transform: rotate(90deg);
    }
}


.loader{
    &-block{
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-item{
        .spin-bg {
            position: relative;
            color: var(--primary-text-color) !important;

            &:before {
                content: "";
                border: 2px solid rgba( var(--secondary-bg-color-rgb), var(--opacity60) );
                border-radius: 50%;
                width: 24px;
                height: 24px;
                display: block;
                position: absolute;
                opacity: 0.7;
            }
        }

        .ant-spin{
            color: inherit;
        }

        .anticon-loading{
            font-size: 24px;
        }
    }
}

.table-skeleton{
    display: block;
    height: 17px !important;

    &.one-quarter{
        width: 25%;
    }

    &.half{
        width: 50%;
    }

    &.three-quarters{
        width: 75%;
    }

    &.full{
        width: 100%;
    }

    span{
        width: 100% !important;
        height: inherit !important;
    }

    & + .table-skeleton{
        margin-top: 10px;
    }
}

.copyright{
    @extend .text-type3-white-op40;
    text-align: center;
}

.modal{

    &-standard{
        min-width: 320px;
        max-width: 480px;
        width: 100% !important;
        margin: 0;
        padding: 0;

        @include media('height<=550px') {


            .form-actions{
                position: initial !important;
                margin: 0 !important;
            }
        }

        @include media('<=tablet') {
            //height: 100%;
            transform-origin: 50% 100% !important;
            position: absolute;
            bottom: 0;
            top: auto !important;
            max-width: 100%;
        }

        h1{
            margin-bottom: 30px;
        }

        .ant-form{

            .ant-form-item:last-child{
                margin-bottom: 0;
            }

            .form-actions{
                margin-top: 30px;
                display: flex;

                & > *{
                    flex: 1;
                    margin-right: 10px;

                    &:last-child{
                        margin: 0;
                    }

                    & + &{
                        margin-left: 10px;
                    }
                }
            }
        }

        .ant-modal{

            &-header{
                padding: 18px 20px 10px !important;
                border: none;

                @include media('>=tablet') {
                    padding-top: 0 !important;
                }
            }

            &-title{
                @extend .text-type3-dark-op60;

                @include media('>=phone') {
                    font-size: 14px;
                }
            }

            &-content,
            &-body{

                @include media('>=tablet') {
                    min-width: 420px;
                }
               // height: 100%;
            }

            &-content{
                background-color: var(--secondary-bg-color);

                padding-bottom: 20px;

                @include media('<=phone') {
                    border-radius: 16px 16px 0 0;
                    overflow: hidden;
                }
            }

            &-body{
                padding: 0 20px 0;
                position: relative;

                @include media('<=tablet') {
                    max-height: calc((var(--vh, 1vh) * 100) - 80px);
                    height: 100%;
                    overflow: auto;
                }
            }

            &-close{

                right: 6px;

                &-x{
                    @extend %transition;
                    right: 0 !important;
                    top: 0 !important;
                    font-size: 22px;
                    color: var(--secondary-text-color);
                    width: 45px;
                    height: 45px;
                    line-height: 50px;

                    &:hover{
                        color: var(--active-text-color);
                    }
                }
            }
        }

        &.full{
            @include media('>phone') {
                width: 100% !important;
                max-width: 100% !important;
                bottom: 0;
                top: auto !important;

            }

            .ant-modal-content{
                height: calc(var(--vh, 1vh) * 100);
                border-radius: 0;


                //.ant-modal-body{
                //    height: 100%;
                //}
            }
        }

        &.dark{

            .ant-modal-content {
                background-color: var(--primary-bg-color);

                .ant-modal-header{
                    background-color: inherit;
                }
            }

            .ant-modal-title{
                @extend h4;
                text-align: center;
                color: rgba(var(--primary-text-color-rgb), var(--opacity40)) !important;

            }

            .ant-modal-close-x{
                color: var(--primary-text-color);
            }
        }

        &.filter-modal,
        &.sort-modal{
            .ant-modal-content{
                background-color: rgba(var(--secondary-bg-color-rgb), var(--opacity95)) !important;

                .ant-modal-header{
                    background-color: transparent !important;
                }
            }
        }

        &.sort-modal{
            .sort-list-wrap{
                margin-top: 15px;
            }

            .radio-list{
                .ant-radio-wrapper{
                    text-transform: initial;
                }
            }

            .actions{
                margin-top: 30px;

                button{
                    width: 100%;
                }
            }
        }
    }
}

.ant-modal {

    &-centered {
        & > * {
            transform-origin: initial !important;
            position: initial;
            bottom: initial;
            top: initial !important;
            width: auto !important;

            .ant-modal-content{
                padding: 20px 0;
                border-radius: 16px;
            }
        }
    }

    &.grey{
        .ant-modal-header{
            background-color: transparent;
            margin-bottom: 10px;

            .ant-modal-title{
                @extend .text-dark-bold;
                font-size: 14px;
            }
        }

        .ant-modal-content{
            background-color: rgba(var(--secondary-bg-color-rgb), var(--opacity95)) !important;

            .ant-form-item-label > label{
                @extend .text-dark-op60;
            }
        }
    }
}

.ant-row-center{
    margin: 0 auto;
}

.ant-result{
    &-title,
    &-subtitle{
        @extend .text;
    }
}

.filter-block{
    .modals{
        button{
            width: 100%;
        }
    }
}

.grid-item-hero{
    .info{
        grid-column-gap: 0;
        grid-row-gap: 15px;
        margin-bottom: 10px;

        h5{
            margin: 0;
        }

        li{
            @extend .text-type3;
            position: relative;
            padding: 0 5px 0 6px;
            overflow-wrap: anywhere;
            min-height: 48px;

            &:before{
                content: "";
                position: absolute;
                left: 0;
                height: 100%;
                width: 1px;
                background-color: var(--primary-bg-color);
            }
        }
    }
}

.ant-carousel{
    height: 100%;

    .slick-arrow{
        background-color: rgba(0, 0, 0, 0.3) !important;
        padding: 5px;
        width: 36px;
        height: 36px;
        z-index: 10;

        span{
            color: #FFF;
            font-size: 20px;
        }

        &.slick-prev{
            border-radius: 6px 0 0 6px;
            left: 0;
            transform: rotate(-180deg);
        }
        &.slick-next{
            border-radius: 6px 0 0 6px;
            right: 0;
        }
    }

    .slider-pages{
        position: absolute;
        top: -10px;
        left: 0;
        bottom: initial;
        right: initial;
        display: block !important;
        margin: initial !important;

        & > *{
            display: block;
            font-size: 15px;
            font-weight: bold;
            color: var(--primary-text-color);
            background-color: var(--active-bg-color);
            padding: 5px 10px !important;
            height: initial !important;
            text-indent: initial !important;
            margin: initial !important;
            box-sizing: initial !important;
            border-radius: 6px;

            &:not(.slick-active){
                display: none;
            }
        }


    }

    & > .slick-slider{
        height: inherit;
        & > .slick-list{
            height: inherit;
            & > .slick-track{
                height: inherit;
                & > .slick-slide{
                    height: inherit;

                    & > *{
                        height: inherit;

                        .photo-viewer{
                            height: inherit;
                        }
                    }
                }
            }
        }
    }
}

.pan-pinch-wrap{
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: column;

    .rotation-wrapper{
        height: calc(100vw - 40px);
        width: calc(100vw - 40px);
        display: flex;
        align-items: center;
        justify-content: center;

        @include media('landscape', "width<=960px") {
            height: calc( var(--vh) * 100 - 150px);
            width: calc( var(--vh) * 100 - 150px);
        }

        @include media('landscape', 'width>=960px') {
            height: calc( var(--vh) * 100 - 240px);
            width: calc( var(--vh) * 100 - 240px);
        }

        img{
            max-width: 100%;
            max-height: 100% !important;
            transform: rotate(0);

            &.r1{
                transform: rotate(90deg);
            }

            &.r2{
                transform: rotate(180deg);
            }

            &.r3{
                transform: rotate(270deg);
            }
        }

        &.loading{
            background-color: rgba( var(--secondary-bg-color-rgb), var(--opacity15) );
            justify-content: center;

            img{
                display: none;
            }
        }
    }

    .react-transform-wrapper{
        height: 100%;
        width: 100%;
    }
}

.photo-viewer{

    &-wrap{
        display: flex;
        flex-direction: column;
        height: 100%;

        h3{
            margin-top: 10px;
            text-align: center;
        }
    }

    &-actions{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;

        .group{
            display: flex;

            & > * + *{
                margin-left: 5px;
            }
        }

        .ant-btn.icon{
            width: 34px;
            height: 34px;

            .anticon{
                font-size: 20px;
            }
        }
    }
}

.photo-modal{
    &-wrap{
        .anticon{
            font-size: 18px;
        }
    }
}

.block-wrap{
    & + .block-wrap{
        margin-top: 10px;
    }
}

.pre-upload-image{
    .image-preview{
        img{
            max-width: 100%;
            max-height: 100%;
            border-radius: 6px;
        }

        &.loading{
            img{
                display: none;
            }
        }
    }
}

.sort-list-group{
    width: 100%;
}

.sort-modal-wrap,
.filter-modal-wrap{
    button {
        text-transform: initial;

        .anticon {
            font-size: 16px;
        }
    }
}

.filter-modal{
    .ant-form{
        label{
            font-size: 14px !important;
            line-height: 1.4em;
            font-weight: bold;
            color: var(--active-text-color) !important;
        }
    }
}

.filter-params-list{
    margin: 0;
    display: flex;
    flex-wrap: wrap;

    & > *{
        margin-top: 10px;
    }
}

.filter-tag{
    font-size: 10px;
    font-weight: bold;
    border-radius: 15px;
    background-color: var(--active-bg-color);
    color: var(--primary-text-color);
    border: none;
    padding: 0 10px;
    display: flex;
    align-items: center;

    .ant-tag-close-icon{
        color: var(--primary-text-color);
    }

    @include media('>=tablet') {
        font-size: 12px;
        font-weight: normal;
    }

    & > span{
        margin-left: 3px;
        text-transform: lowercase;
    }
}

.custom-tabs{
    height: 100%;

    &-switcher{
        display: flex;
        justify-content: center;

        & > * + * {
            margin-left: 20px;
        }

        .ant-btn-primary{
            border: 1px solid var(--active-bg-color);
        }
    }

    &-wrap{
        padding-top: 20px;
    }
}

.new-badge{
    //position: relative;

    &:before{
        content: attr(data-badgetext);
        background: var(--active-bg-color);
        padding: 3px 5px;
        font-size: 12px;
        line-height: 1em;
        border-radius: 6px;
        position: absolute;
        top: -10px;
        left: 0;
    }
}

.ant-result{
    .ant-result-extra{
        display: flex;
        justify-content: center;
    }
}

.overview-statistic{
    padding: 9px 5px !important;
    display: flex;

    @include media('>=tablet') {
        padding: 7px 5px !important;
    }

    &-item{
        padding: 6px 6px 8px;
        width: 16.667%;

        @include media('>=tablet') {
            padding: 8px 6px 10px;
        }

        & + & {
            margin-left: 5px;
        }

        &:not(div){
            background-color: var(--secondary-text-color) !important;
            border-radius: 6px;
            display: block;
            color: var(--primary-text-color);
        }

        & > *{
            color: inherit !important;
            text-align: center;
        }

        h4{
            margin: 0;
            font-size: 16px;
        }

        p{
            font-size: 9px;
            line-height: 1.3em;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
            width: 100%;
            overflow: hidden;

            @include media('>=tablet') {
                font-size: 12px;
            }
        }
    }
}

