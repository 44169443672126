@import "scss/base/colors";

.job-edit-block{
    &-body{
        border-radius: 6px;
        background-color: var(--secondary-bg-color);
        overflow: hidden;
        padding: 5px 5px 9px!important;

        .craft-info{
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .craft-grid-item{
                min-width: 60px;
                margin-right: 15px;

                .job-tag{
                    top: 0;
                }
            }

            h4{
                font-size: 16px;
                @extend .text-dark;
                margin: 0;
            }

            .subcontractor{
                @extend  .text-dark;
                font-size: 10px;
            }

        }

        .ant-form-item{
            margin-bottom: 6px;
        }

        .form-actions{
            margin-top: 7px !important
        }

        .pre-upload-image{

            .image-preview{
                max-width: 100%;
                height: calc(var(--vh, 1) * 40);
                display: flex;
                align-items: center;
                justify-content: center;

                & + .actions{
                    margin-top: 6px;
                }
            }
        }
    }
}
