.bottom-menu-bar{
    background-color: rgba(var(--primary-bg-color-rgb), var(--opacity95));
    border-top: 1px solid #000000;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60px;
    display: flex;
    z-index: 1000;
}