.exhibition-time-block{
    display: flex;
    align-items: center;
    justify-content: space-between;

    .timer{
        color: var(--active-text-color);
        background-color: var(--secondary-bg-color);
        font-weight: bold;
        padding: 6px 14px;
        border-radius: 6px;
        line-height: 1em;
    }
}