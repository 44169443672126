.damage-status-segmented-wrap{
    .ant-segmented{
        background-color: rgba(var(--secondary-bg-color-rgb), var(--opacity15)) !important;
        border-radius: 6px;

        &-thumb{
            border-radius: 6px;
        }

        &-item{
            border-radius: 6px;

            &-label{
                display: flex;
                flex-direction: column;
                line-height: 1em;
                padding-top: 3px;
                padding-bottom: 3px;

                .anticon{
                    font-size: 16px;
                }
            }
        }

        &.new{
            .ant-segmented-thumb,
            .ant-segmented-item-selected{
                background-color: var(--warning-color);
            }
        }

        &.check,
        &.double_check{
            .ant-segmented-thumb,
            .ant-segmented-item-selected{
                background-color: var(--success-color);
            }
        }
    }
}

.damage-add-photo-btn{
    .anticon{
        font-size: 16px;
    }

    .loader-item{
        font-size: 16px !important;
        line-height: 1em;
        width: 18px;
        height: 18px;
        margin-right: 6px;

        .ant-spin{
            line-height: 1em;

            .anticon-loading{
                font-size: 16px !important;
            }
        }
    }
}