@import 'scss/base/colors';
@import "scss/base/helpers";
@import "scss/vendors/include-media";

.stand-search-modal{
    &-wrap{
        & > button {
            &.icon.rounded{
                @extend .color-primary;

                font-size: 26px !important;

                &:hover{
                    @extend .color-active;
                }
            }
        }
    }

    .virtuoso-scroller{

    }

    .ant-modal{
        &-close,
        &-header{
            display: none;
        }

        &-body{
            padding: 0 !important;
            max-height: calc(var(--vh, 1) * 100 - 40px) !important;
        }

        &-content{
            padding-top: 20px;
        }
    }

    .virtuoso-scroller{
        height: calc(var(--vh, 1) * 100 - 100px) !important;
        padding: 0 20px;

        & > * {
            max-width: calc(100% - 40px);
        }
    }

    .close-wrap{
        button{
            background: transparent;
            border: none;
            color: var(--primary-text-color);
            margin-left: 10px;
        }
    }

    .loader-wrap {
        @extend %transition;
        min-height: 40px !important;
        position: fixed;
        left: 0;
        bottom: -40px;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(var(--primary-bg-color-rgb), var(--opacity95));
        opacity: 0;

        &.loading{
            opacity: 1;
            bottom: 0;
        }
    }
}


.stand-photo-modal{

    @include media('>=tablet') {
        &.full .ant-modal-header{
            padding-top: 18px !important;
        }
    }

    &-wrap{
        button {
            .anticon {
                font-size: 18px;
            }

            &.with-photo{
                @extend .color-success;
            }
        }
    }
}


.stand-keys-delete-modal{

    .ant-modal-content{
        padding: 35px 30px !important;
        text-align: center;
    }

    .text{
        @extend .text-type2, .text-dark;
    }

    .actions{
        margin-top: 30px;
    }

}
