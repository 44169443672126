@import 'scss/vendors/include-media';

.furnishing-select-modal{

    .ant-modal-body{
        position: relative;
        margin-bottom: 20px !important;

        .virtuoso-scroller{
            min-height: 360px;

            height: calc(var(--vh, 1) * 100 - 100px) !important;

        }

        .loader-item{
            position: fixed;
            padding: 7px;
            left: 0;
            right: 0;
            bottom: 0;
            text-align: center;
        }

        .furnishing-custom-list-item{
            padding: 6px 0 !important;

            .anticon{
                &.anticon-plus{
                    font-size: 18px !important;
                }
            }

            .photo-modal-wrap{

                .ant-btn{
                    color: var(--secondary-text-color) !important;
                }
            }

            button{
                color: var(--primary-text-color) !important;
                height: 26px;
                width: 26px;
            }
        }


    }

}

.furnishing-refund-modal{
    &-wrap{
        .anticon{
            //transform: scaleX(-1);
        }
    }
}