@import 'scss/vendors/include-media';

.page{
    width: 100%;
    justify-content: center;

    padding: 70px 0 30px;

    @include media('<=phone') {
        padding: 60px 20px 30px;
    }

    @include media('<=360px') {
        padding: 60px 15px 30px;
    }

    @include media('>phone') {
        padding: 60px 20px 30px;
    }



}

.login-page{

    .block{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media('height<=605px') {
            top: initial;
        }
    }

    .copyright{
        padding: 0 15px;
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}

.home-page{
    padding-top: 70px !important;

    .exhibition-statistic-grid{
        margin-top: 20px;

        @include media('height<=670px') {
            margin-top: 14px;
        }
    }

    .exhibition-time-block{
        margin-top: 14px;

        @include media('height<=670px') {
            margin-top: 10px;
        }
    }
}

.exhibitions-page{
    .instruction{
        text-align: center;
        margin: 32px 0;
    }

    .action-wrap{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;

        & > *{
            width: 100%;
            max-width: 360px;
            display: flex;
            align-items: center;
            justify-content: center;

            .anticon{
                font-size: 18px;
            }
        }
    }

    .exhibition-selection-list{
        margin-bottom: 80px;
    }

    .no-exhibitions{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex-direction: column;

        & > *{
            transform: translate( 0, -40px);
        }

        p{
            margin-bottom: 40px;
        }

        .anticon{
            font-size: 175px;
        }
    }

    .app:not(.with-bottom-bar) &.page{
        max-height: calc( var(--vh) * 100) !important;
        overflow: auto;

    }
}

.stands-page{
    .stand-grid{
       margin-top: 15px;
    }

    margin-bottom: 30px !important;

    .no-data{
        height: 100%;
        display: flex;
        align-items: center;
        margin-top: -40px;

        .anticon{
            font-size: 150px;
        }
    }
}

.stand-page{
    .keys-logs-block{
        margin-top: 15px;
    }

    @include media('>=tablet') {
        h3{
            font-size: 20px;
        }

        .text-label{
            font-size: 14px !important;
        }
    }

}

.stand-keys-page{

    h3{
        text-align: center;
    }

    .page-actions{

        width: 100%;

        button{
            width: 100%;

            .anticon{
                font-size: 18px;
            }
        }
    }

    .keys-list-wrap{
        margin-bottom: 30px;

        &.empty{
            height: calc( var(--vh) * 100 - 240px);
            display: flex;
        }
    }

    .no-data{
        .no-data-message{
            margin-bottom: 60px;
        }

        .anticon{
            font-size: 160px;
        }
    }
}

.hall-page{
    background-color: var(--secondary-bg-color);

    .app.with-bottom-bar &.page{
        padding-top: 50px !important;
        padding-bottom: 60px !important;
    }

    .empty{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h3{
            margin: 15px 0 0;
        }

        .anticon{
            font-size: 100px;
        }
    }

}

.view-damages-page,
.jobs-page{
    .virtuoso-scroller{
        margin-top: 15px;
    }

    .no-data{
        height: 100%;
        display: flex;
        align-items: center;
        margin-top: -40px;

        .anticon{
            font-size: 110px;
        }
    }

    margin-bottom: 150px !important;
}

.more-page{
    .action-wrap{
        margin-top: 20px;
        width: 100%;

        button{
            width: 100%;

            .anticon{
                font-size: 18px;
            }
        }
    }

    .ant-col{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
}

.help-imprint-page{
    color: rgba(var(--primary-text-color-rgb),  var(--opacity60)) !important;

    b{
        color: var(--primary-text-color);
    }

    a{
        margin-left: 5px;
        color: var(--active-text-color);

        &:hover{
            text-decoration: underline;
            color: var(--active-text-color);
        }
    }
}

.stand-log-page{
    h3{
        text-align: center;
        margin-top: 5px;
        margin-bottom: 10px;
    }
}

.furnishing-page{
    margin-bottom: 70px !important;
}

.documents-page{
    .no-data{
        height: 100%;
        display: flex;
        align-items: center;
        margin-top: -40px;
    }
}

.page-404{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}