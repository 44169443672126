@import "scss/base/colors";

.stand-keys-list{

    &-item{
        display: flex;

        & > *:first-child{
            min-width: 25px;
            color: var(--active-text-color);
            font-size: 18px;
            font-weight: bold;
            align-items: center;
            display: flex;
        }

        .data{
            flex: 1;
            padding: 0 10px 0 15px;
            position: relative;

            h4{
                @extend .text-white-op40;
                margin: 0;
            }

            p{
                line-height: 1em;
            }

            &:before{
                content: "";
                position: absolute;
                left: 0;
                height: 100%;
                width: 1px;
                background-color: var(--primary-bg-color);
            }
        }

        .actions{
            display: flex;

            & > * + *{
                margin-left: 10px;
            }

            button{
                height: 34px;
                width: 34px;
            }
        }

        & + &{
            margin-top: 10px;
        }
    }

}